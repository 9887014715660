<template>
  <v-btn
    class="font-weight-medium text-h5 text-uppercase"
    min-width="0"
    color="white"
    :to="path"
    exact
  >
    <span style="color: black">{{ text }}</span>
  </v-btn>
</template>
<script>
export default {
  data() {
    return {
      text: "Вход в личный кабинет",
      path: "/auth/login",
    };
  },
};
</script>